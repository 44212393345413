<template>
  <header-component/>
  <router-view/>
</template>

<script>
import headerComponent from "@/components/header.vue"



export default {
  name: 'App',
  components: {
    headerComponent,
  }
}
</script>
